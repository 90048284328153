import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeader from '~/components/PF2MModal/ModalHeader';
import withModal from '~/components/PF2MModal/withModal';
import PropTypes from 'prop-types';
import { Card, CardBody } from '~/components/Card';
import { useTranslation } from 'react-i18next';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  FormControl, FormGroup, FormLabel, withStyles,
} from '@material-ui/core';
import { Field, Formik } from 'formik';
import { clearCurrentOperationalControlFilter, setCurrentOperationalControlFilter } from '~/store/dispatch/actions';
import PF2MClearFiltersButton from '~/components/PF2MClearFiltersButton';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import styles from '../styles';
import PF2MDatepicker from '~/components/PF2MDatepicker';
import { parseDateToTimestamp, newMomentDate } from '~/utils/moment';

const defaultValues = {
  start_date: newMomentDate(),
  end_date: newMomentDate(),
  origin_point_id: 0,
  destination_point_id: 0,
  material_id: 0,
  operator_id: 0,
  equip_id: 0,
};

const DropDown = ({
  name, values, title, classes, collection, handleChange,
}) => {
  const { t: translate } = useTranslation();

  return (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          {...classes.field}
          className={classes.field}
          placeholder={null}
          value={values[name]}
          onChange={e => handleChange(name, e.target.value)}
        >
          {[{ value: defaultValues[name], label: translate('common:All') }]
            .concat(...collection
              .map(e => ({ value: e.id, label: e.name })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );
};

DropDown.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  collection: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.any.isRequired,
  })).isRequired,
  classes: PropTypes.shape({
    field: PropTypes.string.isRequired,
    formLabel: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

const DispatchOperationControlModalFilter = React.forwardRef((
  { closeModal, classes, equipmentOptionsFilter },
  _ref,
) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const subElements = useSelector(s => s.nref.subElements);
  const materials = useSelector(s => s.manager.materials);
  const operators = useSelector(s => s.manager.operators);
  const equipments = useSelector(s => s.manager.equipments);
  const currentFilter = useSelector(s => s.dispatch.currentOperationalFilter);

  const onSubmit = (values) => {
    const sendedValues = {
      start_date: values.start_date,
      end_date: values.end_date,
    };
    if (values.material_id) sendedValues.material_id = values.material_id;
    if (values.operator_id) sendedValues.operator_id = values.operator_id;
    if (values.equip_id) sendedValues.equip_id = values.equip_id;
    if (values.origin_point_id) sendedValues.origin_point_id = values.origin_point_id;
    if (values.destination_point_id) {
      sendedValues.destination_point_id = values.destination_point_id;
    }
    dispatch(setCurrentOperationalControlFilter(sendedValues));
    closeModal();
  };

  const validate = (values) => {
    try {
      const errors = {};
      const startTimestamp = parseDateToTimestamp(values.start_date, '00:00:00');
      const endTimestamp = parseDateToTimestamp(values.end_date, '23:59:59');
      const hasDateErrors = startTimestamp > endTimestamp;
      if (hasDateErrors) {
        errors.start_date = hasDateErrors;
        errors.end_date = hasDateErrors;
      }
      return errors;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  };

  return (
    <Card style={{ width: '50vw', height: '50vh' }}>
      <ModalHeader closeModal={closeModal} title={translate('common:Filters')} />
      <Formik
        initialValues={currentFilter}
        onSubmit={onSubmit}
        validate={validate}
      >
        {form => (
          <CardBody style={styles.filterFormContainer}>
            <Field name="start_date" component={PF2MDatepicker} />
            <Field name="end_date" component={PF2MDatepicker} />
            <div />

            <DropDown
              values={form.values}
              name="origin_point_id"
              title={translate('common:SubOriginElement')}
              collection={subElements}
              classes={classes}
              handleChange={form.setFieldValue}
            />
            <DropDown
              values={form.values}
              name="destination_point_id"
              title={translate('common:SubDestinationElement')}
              collection={subElements}
              classes={classes}
              handleChange={form.setFieldValue}
            />
            <DropDown
              values={form.values}
              name="material_id"
              title={translate('common:Material')}
              collection={materials}
              classes={classes}
              handleChange={form.setFieldValue}
            />

            <DropDown
              values={form.values}
              name="operator_id"
              title={translate('common:Operator')}
              collection={operators.map(op => ({ name: op.name, id: op.id_operator }))}
              classes={classes}
              handleChange={form.setFieldValue}
            />
            <DropDown
              values={form.values}
              name="equip_id"
              title={translate('common:TruckEquipment')}
              collection={equipments.filter(equipmentOptionsFilter)}
              classes={classes}
              handleChange={form.setFieldValue}
            />
            <div />

            <div />
            <PF2MClearFiltersButton
              onClick={() => {
                dispatch(clearCurrentOperationalControlFilter());
                form.resetForm();
                closeModal();
              }}
            />
            <PF2MSearchButton onClick={form.handleSubmit} />
          </CardBody>
        )}
      </Formik>
    </Card>
  );
});

DispatchOperationControlModalFilter.propTypes = {
  closeModal: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  equipmentOptionsFilter: PropTypes.func.isRequired,
};

export default withStyles(styles)(withModal(DispatchOperationControlModalFilter));
