import React from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CleanFilterIcon from '~/assets/clean_filter.svg';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import { newMomentDate } from '~/utils/moment';
import {
  restoreEquipmentSchedulesFilter,
  setCurrentEquipmentSchedulesFilter,
} from '~/store/dispatch/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const EquipmentProgramsFilter = ({ classes, statusId }) => {
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const renderDatePicker = (filter, key, label) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {label}
      </FormLabel>
      <FormGroup>
        <KeyboardDatePicker
          clearable
          inputVariant="outlined"
          InputProps={{
            style: {
              height: 40,
              width: 200,
              color: '#647886',
            },
          }}
          format={translate('date:DateFormat')}
          autoOk
          maxDate={
            key === 'startDate'
              ? currentFilter.endDate
              : newMomentDate().add(30, 'days')
          }
          minDate={
            key === 'endDate'
              ? currentFilter.startDate
              : newMomentDate().subtract(30, 'days')
          }
          onChange={(e) => {
            const newFilter = {
              ...currentFilter,
              status_id: statusId || 0,
              [key]: e,
            };
            dispatch(setCurrentEquipmentSchedulesFilter(newFilter));
          }}
          value={filter[key]}
          invalidDateMessage={translate('validation:InvalidDate')}
        />
      </FormGroup>
    </FormControl>
  );

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
  ) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key]}
          onChange={(e) => {
            const newFilter = {
              ...currentFilter,
              [key]: e.target.value,
              status_id: statusId || 0,
            };
            if (key === 'equipType') {
              newFilter.equipGroup = 0;
              newFilter.equip = 0;
            } else if (key === 'equipGroup') {
              newFilter.equip = 0;
            } else if (key === 'element') {
              newFilter.subElement = 0;
            }
            dispatch(setCurrentEquipmentSchedulesFilter(newFilter));
          }}
        >
          {[{ value: initialValue, label: translate('common:All') }]
            .concat(collection
              .map(e => ({ value: e.id, label: e.name })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <>
      <div style={{ display: 'flex' }}>
        {renderDatePicker(currentFilter, 'startDate', translate('common:StartDate'))}
        {renderDatePicker(currentFilter, 'endDate', translate('common:EndDate'))}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        {renderFormControl(currentFilter, 'equipType', translate('common:EquipmentType'), equipmentTypes)}
        {renderFormControl(currentFilter, 'equipGroup', translate('common:EquipmentGroup'), equipmentGroups
          .filter(c => c.id_equipament === currentFilter.equipType))}
        {renderFormControl(currentFilter, 'equip', translate('common:Equipment'), equipments
          .filter(c => c.id_group === currentFilter.equipGroup))}
      </div>
      <div style={{ display: 'flex', marginTop: 10 }}>
        {renderFormControl(currentFilter, 'element', translate('common:Element'), elements)}
        {renderFormControl(currentFilter, 'subElement', translate('common:SubElement'), subElements
          .filter(c => c.id_element === currentFilter.element))}
        <FormControl style={{ width: 200 }}>
          <FormGroup>
            <PF2MButton
              color="primary"
              onClick={() => dispatch(restoreEquipmentSchedulesFilter())}
              style={{ marginTop: 15 }}
            >
              <img src={CleanFilterIcon} className={classes.icon} alt="" />
              {translate('common:ClearFilters')}
            </PF2MButton>
          </FormGroup>
        </FormControl>
      </div>
    </>
  );
};

EquipmentProgramsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  statusId: PropTypes.number,
};

EquipmentProgramsFilter.defaultProps = {
  statusId: false,
};

export default withStyles(styles)(EquipmentProgramsFilter);
