import React, { useState, useEffect } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Accordion,
  Typography,
  AccordionDetails,
  styled,
} from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { LoadAllocationModal, TruckAllocationModal, TruckAllocationModeModal } from './AllocationModal';
import AllocationRow from './AllocationRow';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MAddButton from '~/components/PF2MAddButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { DispatchStatus, DispatchDragNDropTypes } from '~/utils';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getMaterials,
  getTabletConfiguration,
} from '~/store/manager/actions';
import { getElements, getSubElements } from '~/store/nref/actions';
import {
  setSelectedLoadAllocation,
  setSelectedTruckAllocation,
  getLoadAllocations,
  getTruckAllocations,
  deallocateAllLoadAllocations,
  deallocateTruckAllocations,
  updateLoadAllocations,
  updateTruckAllocations,
  deallocateLoadAllocations,
  setPendingTruckAllocationUpdate,
} from '~/store/dispatch/actions';
import PF2MButton from '~/components/PF2MButton';

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    {...props}
  />
))(() => ({
  minHeight: '10px !important',
  '& .MuiAccordionSummary-content': {
    margin: 5,
  },
}));

const TRUCK = 2;
const AllocationsTab = ({ classes }) => {
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const { t: translate } = useTranslation();

  const equipmentTypes = useSelector(
    state => state.manager.equipmentTypes.filter(r => [1, 2, 15, 29].includes(r.id)),
  );
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const truckAllocations = useSelector(state => state.dispatch.truckAllocations);
  const loadAllocations = useSelector(state => state.dispatch.loadAllocations);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const availablePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckAllocations
        .filter(r => (!r.load_allocation_id && r.status !== DispatchStatus.MA)),
      ...state.dispatch.loadAllocations
        .filter(r => (!r.priority && r.status !== DispatchStatus.MA)),
    ],
  );
  const maintenancePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckAllocations
        .filter(r => (!r.load_allocation_id && r.status === DispatchStatus.MA)),
      ...state.dispatch.loadAllocations
        .filter(r => (!r.priority && r.status === DispatchStatus.MA)),
    ],
  );
  const pendingTruckAllocationUpdate = useSelector(
    state => state.dispatch.pendingTruckAllocationUpdate,
  );
  const [dataLoaded, setDataLoaded] = useState(false);
  const [poolEquipmentType, setPoolEquipmentType] = useState(0);
  const [poolEquipmentGroup, setPoolEquipmentGroup] = useState(0);
  const [isDeallocationAlertOpen, setIsDeallocationAlertOpen] = useState(false);
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false);
  const [isTruckModalOpen, setIsTruckModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    async function fetchData() {
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getMaterials());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getTabletConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (tabletConfiguration.find(k => k.key === 'dispatch_update_time')) {
      const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [tabletConfiguration]);

  const applyFiltersToPoolList = (list) => {
    if (poolEquipmentGroup) {
      return list.filter(r => r.equip_group_id === poolEquipmentGroup);
    }
    if (poolEquipmentType) {
      return list.filter(r => r.equip_type_id === poolEquipmentType);
    }
    return list;
  };

  const renderEmptyRow = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 90,
        backgroundColor: '#F9F9F9',
        color: '#647886',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Roboto',
      }}
    >
      <span>{translate('common:NoRegisteredAllocations')}</span>
    </div>
  );

  const renderTableActionButtons = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 90,
      }}
    >
      <PF2MButton
        color="primary"
        onClick={() => setIsDeallocationAlertOpen(true)}
      >
        <DeleteIcon className={classes.icon} />
        {translate('common:ClearAllocations')}
      </PF2MButton>
      <PF2MAddButton
        text={translate('common:NewAllocation')}
        onClick={() => {
          dispatch(setSelectedLoadAllocation(null));
          setIsLoadModalOpen(true);
        }}
      />
    </div>
  );

  const renderPoolFilters = () => (
    <div style={{ width: '15%' }}>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentType')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentType}
            placeholder={null}
            onChange={(e) => {
              setPoolEquipmentType(e.target.value);
              setPoolEquipmentGroup(0);
            }}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentTypes
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
      <FormControl style={{ marginTop: 10 }}>
        <FormLabel className={classes.formLabel}>
          {`${translate('common:EquipmentGroup')}:`}
        </FormLabel>
        <FormGroup>
          <PF2MSearchSelectOutlined
            value={poolEquipmentGroup}
            placeholder={null}
            onChange={(e) => {
              setPoolEquipmentGroup(e.target.value);
            }}
            className={classnames(classes.field)}
          >
            {[{ value: 0, label: translate('common:Select') }]
              .concat(...equipmentGroups
                .filter(r => r.id_equipament === poolEquipmentType)
                .map(e => ({ value: e.id, label: e.name })))}
          </PF2MSearchSelectOutlined>
        </FormGroup>
      </FormControl>
    </div>
  );

  const renderPools = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      {renderPoolFilters()}
      <div style={{
        display: 'flex',
        marginTop: 10,
        width: '85%',
      }}
      >
        <PF2MEquipmentPool
          equips={applyFiltersToPoolList(availablePoolEquips)}
          onEquipClick={(equip) => {
            if (equip.equip_type_id === TRUCK) {
              dispatch(setSelectedTruckAllocation(equip));
              setIsTruckModalOpen(true);
            } else {
              dispatch(setSelectedLoadAllocation(equip));
              setIsLoadModalOpen(true);
            }
          }}
          onItemDropped={(id, type) => {
            if (type === DispatchDragNDropTypes.TRUCK) {
              dispatch(deallocateTruckAllocations(id));
            } else {
              dispatch(deallocateLoadAllocations(id));
            }
          }}
          headerText={translate('common:AvailableEquipments').toUpperCase()}
        />
        <PF2MEquipmentPool
          equips={applyFiltersToPoolList(maintenancePoolEquips)}
          onEquipClick={(equip) => {
            if (equip.equip_type_id === TRUCK) {
              dispatch(setSelectedTruckAllocation(equip));
              setIsTruckModalOpen(true);
            } else {
              dispatch(setSelectedLoadAllocation(equip));
              setIsLoadModalOpen(true);
            }
          }}
          onItemDropped={(id, type) => {
            if (type === DispatchDragNDropTypes.TRUCK) {
              dispatch(deallocateTruckAllocations(id));
            } else {
              dispatch(deallocateLoadAllocations(id));
            }
          }}
          headerText={translate('common:MaintenanceEquipments').toUpperCase()}
          customStyles={{ marginLeft: 10 }}
        />
      </div>
    </div>
  );

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      <div style={{
        width: '8%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Priority')}</span>
      </div>
      <div style={{
        width: '4%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Group')}</span>
      </div>
      <div style={{
        width: '6%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>Origem</span>
      </div>
      <div style={{
        width: '9%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:LoadEquipment')}</span>
      </div>
      <div style={{
        width: '65%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:AllocatedTrucks')}</span>
      </div>
    </div>
  );

  const renderAllocationTable = () => (
    <div style={{ width: '100%', maxHeight: handle.active ? 750 : 500 }}>
      {renderTableHeader()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        maxHeight: expanded ? 450 : 650,
        transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        height: 'auto',
      }}
      >
        <>
          {
            loadAllocations && loadAllocations.filter(r => !!r.priority).length
              ? loadAllocations.filter(r => !!r.priority).map(
                (r, i) => (
                  <AllocationRow
                    key={r.id}
                    loadAllocation={r}
                    onTruckDrop={(item, allocationId) => {
                      if (!item.load_allocation_id) {
                        dispatch(updateTruckAllocations(item, 'load_allocation_id', allocationId));
                        return;
                      }

                      const newTruckAllocation = {
                        ...item,
                        load_allocation_id: allocationId,
                      };
                      dispatch(setPendingTruckAllocationUpdate(newTruckAllocation));
                    }}
                    onLoadDrop={(item, materialMappings) => {
                      dispatch(updateLoadAllocations(item, materialMappings || []));
                    }}
                    loadAllocations={loadAllocations}
                    trucks={truckAllocations.filter(x => x.load_allocation_id === r.id)}
                    isFirst={i === 0}
                    isLast={i === loadAllocations.length - 1}
                    openLoadModal={setIsLoadModalOpen}
                    openTruckModal={setIsTruckModalOpen}
                  />
                ),
              )
              : renderEmptyRow()
          }
        </>
      </PF2MScrollbar>
      {renderTableActionButtons()}
    </div>
  );


  return (
    <FullScreen handle={handle}>
      <div className={classes.tabContainer}>
        {/* <PF2MFullScreenButton
          isFullScreenActive={handle.active}
          open={handle.enter}
          close={handle.exit}
        /> */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '86vh',
          }}
        >
          {renderAllocationTable()}
          <Accordion
            defaultExpanded
            onChange={() => setExpanded(!expanded)}
            expanded={expanded}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="1"
            >
              <Typography>{ translate('common:AvailableEquipments') }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderPools()}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <LoadAllocationModal isOpen={isLoadModalOpen} close={() => setIsLoadModalOpen(false)} />
      <TruckAllocationModal
        modalData={isTruckModalOpen}
        closeModal={() => setIsTruckModalOpen(false)}
      />
      <TruckAllocationModeModal
        isOpen={!!pendingTruckAllocationUpdate}
        close={() => dispatch(setPendingTruckAllocationUpdate(null))}
      />

      <PF2MAlertDialog
        hasCancel
        onClose={() => setIsDeallocationAlertOpen(false)}
        confirmText={translate('common:Yes')}
        description={translate('validation:ThisActionWillClearAllYourAllocations')}
        open={isDeallocationAlertOpen}
        onConfirm={() => {
          setIsDeallocationAlertOpen(false);
          dispatch(deallocateAllLoadAllocations());
        }}
      />
    </FullScreen>
  );
};

AllocationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

AllocationsTab.defaultProps = {};

export default withStyles(styles)(AllocationsTab);
