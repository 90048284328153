/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { handleErrors } from '../common';

const setElements = elements => ({
  type: types.SET_NREF_ELEMENTS,
  elements,
});

const setSubElements = subElements => ({
  type: types.SET_NREF_SUBELEMENTS,
  subElements,
});

export const getElements = () => async (dispatch, _getState) => {
  // if (getState().nref.elements.length > 0) return;
  api.get('/nref/elements')
    .then(res => dispatch(setElements(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};

export const getSubElements = () => async (dispatch, _getState) => {
  // if (getState().nref.subElements.length > 0) return;
  api.get('/nref/subelements')
    .then(res => dispatch(setSubElements(res.data?.result || [])))
    .catch(err => dispatch(handleErrors(err.response)));
};
