import React, { useState, useEffect } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import Footer from '~/pages/Manager/Footer';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MClearFiltersButton from '~/components/PF2MClearFiltersButton';
import {
  getEquipments,
  getMaterials,
} from '~/store/manager/actions';
import { getElements, getSubElements } from '~/store/nref/actions';
import {
  getLoadMaterialMappings,
  setLoadMaterialMappingsValue,
  resetLoadMaterialMappings,
  updateLoadMaterialMappings,
  clearLoadMaterialMappings,
} from '~/store/dispatch/actions';

const MappingsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const isLoadMaterialMappingsDirty = useSelector(
    state => state.dispatch.isLoadMaterialMappingsDirty,
  );
  const equipments = useSelector(
    state => state.manager.equipments.filter(r => [1, 15].includes(r.id_equip)),
  );
  const materials = useSelector(state => state.manager.materials);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const loadMaterialMappings = useSelector(state => state.dispatch.loadMaterialMappings);
  const [selectedEquipment, setSelectedEquipment] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState(0);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      dispatch(getEquipments());
      dispatch(getMaterials());
      dispatch(getElements());
      dispatch(getSubElements());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  useEffect(() => {
    if (dataLoaded && (selectedEquipment || selectedMaterial)) {
      dispatch(getLoadMaterialMappings({
        equipmentId: selectedEquipment,
        materialId: selectedMaterial,
      }));
    } else {
      dispatch(clearLoadMaterialMappings());
    }
  }, [dataLoaded, selectedEquipment, selectedMaterial, dispatch]);

  const columns = [
    {
      field: 'equip_name',
      title: translate('common:Equipment'),
    },
    {
      field: 'material_name', title: translate('common:Material'),
    },
    {
      field: 'first_element_id',
      title: `${translate('common:Element')} 01`,
      selectItems: elements,
      editable: true,
    },
    {
      field: 'first_element_point_id',
      title: `${translate('common:SubElement')} 01`,
      selectItems: subElements,
      filterFunction: (item, row) => (row.first_element_id
        ? row.first_element_id === item.id_element
        : true),
      editable: true,
    },
    {
      field: 'second_element_id',
      title: `${translate('common:Element')} 02`,
      selectItems: elements,
      editable: true,
    },
    {
      field: 'second_element_point_id',
      title: `${translate('common:SubElement')} 02`,
      selectItems: subElements,
      filterFunction: (item, row) => (row.second_element_id
        ? row.second_element_id === item.id_element
        : true),
      editable: true,
    },
  ];

  const renderHeader = () => (
    <>
      <div className={classes.labelTypeSelector}>
        {`${translate('common:Filters')}:`}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <FormControl>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:Equipment')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              value={selectedEquipment}
              placeholder={null}
              onChange={(e) => {
                setSelectedEquipment(e.target.value);
              }}
              className={classnames(classes.field)}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...equipments
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl className={classes.classGroupName}>
          <FormLabel className={classes.formLabel}>
            {`${translate('common:Material')}:`}
          </FormLabel>
          <FormGroup>
            <PF2MSearchSelectOutlined
              value={selectedMaterial}
              placeholder={null}
              className={classnames(classes.field)}
              onChange={(e) => {
                setSelectedMaterial(e.target.value);
              }}
            >
              {[{ value: 0, label: translate('common:Select') }]
                .concat(...materials
                  .map(e => ({ value: e.id, label: e.name })))}
            </PF2MSearchSelectOutlined>
          </FormGroup>
        </FormControl>
        <FormControl className={classes.classGroupName}>
          <div className={classes.fieldsContainer}>
            <PF2MClearFiltersButton
              style={{ marginTop: '20px' }}
              size={'medium'}
              onClick={() => {
                setSelectedEquipment(0);
                setSelectedMaterial(0);
              }}
            />
          </div>
        </FormControl>
      </div>
    </>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={loadMaterialMappings}
        columns={columns}
        keyExtractor={row => `${row.equip_id}${row.material_id}`}
        updateItem={
          (row, field, value) => dispatch(setLoadMaterialMappingsValue(row, field, value))
        }
        noDataMessage={translate('common:ApplyFilterToFetchData')}
      />
      <Footer
        isDirty={isLoadMaterialMappingsDirty}
        discard={() => dispatch(resetLoadMaterialMappings())}
        sendData={() => dispatch(updateLoadMaterialMappings(loadMaterialMappings, {
          equipmentId: selectedEquipment,
          materialId: selectedMaterial,
        }))}
      />
    </div>
  );
};

MappingsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

MappingsTab.defaultProps = {};

export default withStyles(styles)(MappingsTab);
