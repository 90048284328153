import * as types from './types';

const INITIAL_STATE = {
  elements: [],
  subElements: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_NREF_ELEMENTS: {
      return { ...state, elements: action.elements };
    }
    case types.SET_NREF_SUBELEMENTS: {
      return { ...state, subElements: action.subElements };
    }
    default: {
      return state;
    }
  }
}
