import React, { useState } from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CleanFilterIcon from '~/assets/clean_filter.svg';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@material-ui/icons';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import { newMomentDate } from '~/utils/moment';
import {
  restoreEquipmentSchedulesFilter,
  setCurrentEquipmentSchedulesFilter,
} from '~/store/dispatch/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';

const EquipmentProgramsFilterModal = ({ classes, statusId, setModalOpen }) => {
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);
  const [filters, setFilters] = useState(currentFilter);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const renderDatePicker = (filter, key, label, maxDate, minDate) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {label}
      </FormLabel>
      <FormGroup>
        <KeyboardDatePicker
          clearable
          inputVariant="outlined"
          InputProps={{
            style: {
              height: 40,
              width: 200,
              color: '#647886',
            },
          }}
          format={translate('date:DateFormat')}
          autoOk
          maxDate={maxDate}
          minDate={minDate}
          onChange={e => setFilters((oldFilters) => {
            if (oldFilters[key] === e) return oldFilters;
            const newFilter = {
              ...oldFilters,
              status_id: statusId || 0,
              [key]: e,
            };
            return newFilter;
          })}
          value={filter[key]}
          invalidDateMessage={translate('validation:InvalidDate')}
        />
      </FormGroup>
    </FormControl>
  );

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
  ) => (
    <FormControl style={{ marginRight: '10px', width: 200 }}>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key]}
          onChange={e => setFilters((oldFilters) => {
            if (oldFilters[key] === e.target.value) return oldFilters;
            const newFilter = {
              ...oldFilters,
              [key]: e.target.value,
            };
            if (key === 'equipType') {
              newFilter.equipGroup = 0;
              newFilter.equip = 0;
            } else if (key === 'equipGroup') {
              newFilter.equip = 0;
            } else if (key === 'element') {
              newFilter.subElement = 0;
            }
            return newFilter;
          })}
        >
          {[{ value: initialValue, label: translate('common:All') }]
            .concat(collection
              .map(e => ({ value: e.id, label: e.name })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <img src={CleanFilterIcon} style={{ margin: 6 }} className={classes.icon} alt="" />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <div style={{ display: 'flex' }}>
            {renderDatePicker(
              filters,
              'startDate',
              translate('common:StartDate'),
              filters.endDate,
              newMomentDate().subtract(30, 'days'),
            )}
            {renderDatePicker(
              filters,
              'endDate',
              translate('common:EndDate'),
              newMomentDate().add(30, 'days'),
              filters.startDate,
            )}
          </div>
          <div style={{ display: 'flex', marginTop: 10 }}>
            {renderFormControl(filters, 'equipType', translate('common:EquipmentType'), equipmentTypes)}
            {renderFormControl(filters, 'equipGroup', translate('common:EquipmentGroup'), equipmentGroups
              .filter(c => c.id_equipament === filters.equipType))}
            {renderFormControl(filters, 'equip', translate('common:Equipment'), equipments
              .filter(c => c.id_group === filters.equipGroup))}
          </div>
          <div style={{ display: 'flex', marginTop: 10, justifyContent: 'left' }}>
            {renderFormControl(filters, 'element', translate('common:Element'), elements)}
            {renderFormControl(filters, 'subElement', translate('common:SubElement'), subElements
              .filter(c => c.id_element === filters.element))}
          </div>
          <div style={{ textAlign: 'center' }}>
            <PF2MButton
              color="primary"
              onClick={() => dispatch(restoreEquipmentSchedulesFilter())}
              style={{ marginTop: 15 }}
            >
              <img src={CleanFilterIcon} className={classes.icon} alt="" />
              {translate('common:ClearFilters')}
            </PF2MButton>
            <PF2MButton
              type="button"
              size="medium"
              style={{ marginTop: '10px' }}
              onClick={() => {
                dispatch(setCurrentEquipmentSchedulesFilter(filters));
                setModalOpen(0);
              }}
            >
              <SearchOutlined className={classes.icon} />
              {translate('common:Filter')}
            </PF2MButton>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

EquipmentProgramsFilterModal.propTypes = {
  classes: PropTypes.object.isRequired,
  statusId: PropTypes.bool,
  setModalOpen: PropTypes.func,
};

EquipmentProgramsFilterModal.defaultProps = {
  statusId: false,
  setModalOpen: () => null,
};

export default withStyles(styles)(EquipmentProgramsFilterModal);
