import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PF2MTable from '~/components/PF2MTable';
import {
  setEquipmentsScheduleValue,
  updateEquipmentsSchedules,
  getEquipmentsSchedules,
  getEquipmentScheduleStatuses,
} from '~/store/dispatch/actions';
import { getElements, getSubElements } from '~/store/nref/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getCodeGroups,
  getCodeTypes,
  getCodes,
} from '~/store/manager/actions';
import EquipmentProgramsFilter from '../EquipmentProgramsFilter';
import styles from '../styles';

const PendenciesTab = ({ classes }) => {
  const { t: translate } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const APPROVE_STATUS = 4;
  const DISAPPROVE_STATUS = 5;
  // eslint-disable-next-line max-len
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);

  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentsSchedules.length >= 1 && equipmentsSchedules.some(e => e.update_status === 'U')) {
      dispatch(updateEquipmentsSchedules(equipmentsSchedules, { ...currentFilter, status_id: 3 }));
    }
  }, [equipmentsSchedules, currentFilter, dispatch]);

  useEffect(() => {
    async function fetchData() {
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());
      dispatch(getCodes());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getEquipmentsSchedules({ status_id: 3 }));
      dispatch(getEquipmentScheduleStatuses());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch, currentFilter]);

  function handleMask(value) { return (value ? [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/] : []); }

  const columns = [
    {
      field: 'approve',
      title: translate('common:Approve'),
      hidden: true,
    },
    {
      field: 'start_date',
      title: translate('common:StartDate'),
      editable: false,
      updateOnChange: true,
      width: '150px',
    },
    {
      field: 'start_time',
      title: translate('common:InitialTime'),
      editable: false,
      updateOnChange: true,
      width: '150px',
      timePickerFormat: 'HH:mm:ss',
      handleMask,
    },
    {
      field: 'duration',
      title: translate('common:Duration'),
      editable: false,
      updateOnChange: true,
      durationField: true,
      width: '150px',
      handleMask,
    },
    {
      field: 'equipaments_id',
      title: translate('common:EquipmentType'),
      selectItems: equipmentTypes,
      width: '160px',
      editFunc: () => false,
      editable: false,
    },
    {
      field: 'equipament_groups_id',
      title: translate('common:EquipmentGroup'),
      selectItems: equipmentGroups,
      filterKey: 'id_equipament',
      filterValue: 'equipaments_id',
      width: '160px',
      editFunc: () => false,
      editable: false,
    },
    {
      field: 'group_equipament_links_id',
      title: translate('common:Equipment'),
      selectItems: equipments,
      filterKey: 'id_group',
      filterValue: 'equipament_groups_id',
      width: '160px',
      editFunc: () => false,
      editable: false,
    },
    {
      field: 'code_types_id',
      title: translate('common:CodeType'),
      editable: false,
      selectItems: codeTypes,
      defaultValue: -1,
      width: '160px',
    },
    {
      field: 'code_groups_id',
      title: translate('common:CodeGroup'),
      editable: false,
      selectItems: codeGroups,
      width: '160px',
      // itemKey: 'id',
      filterFunction: (item, row) => row.code_types_id === item.code_type
        && row.equipaments_id === item.id_equip,
    },
    {
      field: 'codes_id',
      title: translate('common:Code'),
      editable: false,
      selectItems: codes,
      width: '160px',
      // itemKey: 'id',
      filterFunction: (item, row) => row.code_groups_id === item.id_group
        && row.code_types_id === item.code_type
        && row.equipaments_id === item.id_equip,
    },
    {
      field: 'flowchart_element_id',
      title: translate('common:Element'),
      editable: false,
      selectItems: elements,
      width: '160px',
    },
    {
      field: 'flowchart_element_point_id',
      title: translate('common:SubElement'),
      editable: false,
      selectItems: subElements,
      filterKey: 'id_element',
      filterValue: 'flowchart_element_id',
      width: '160px',
    },
  ];

  const renderHeader = () => (
    <div>
      <EquipmentProgramsFilter statusId={3} />
    </div>
  );

  const setStatusId = (row, id) => {
    dispatch(setEquipmentsScheduleValue(row, 'status_id', id));
  };

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MTable
        data={equipmentsSchedules}
        columns={columns}
        updateItem={(row, field, value) => {
          dispatch(setEquipmentsScheduleValue(row, field, value));
        }}
        approveItem={(row) => { setStatusId(row, APPROVE_STATUS); }}
        disapproveItem={(row) => { setStatusId(row, DISAPPROVE_STATUS); }}
        className={classes.table}
      />
    </div>
  );
};

PendenciesTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

PendenciesTab.defaultProps = {};

export default withStyles(styles)(PendenciesTab);
