import React, {
  useMemo, useState, useRef, useEffect,
} from 'react';
import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Add from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import CreateIcon from '@material-ui/icons/Create';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PF2MButton from '~/components/PF2MButton';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import {
  addEquipmentsSchedule,
  getEquipmentScheduleStatuses,
  getEquipmentsSchedules,
  getPreventiveTypes,
  updateEquipmentsSchedules,
} from '~/store/dispatch/actions';
import PF2MDatePickerOutlined from '~/components/PF2MDatePickerOutlined';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import {
  newMomentDate,
  formatDateToServerFormat,
  formatTime,
  verifyTime,
  parseDateToServerFormat,
} from '~/utils/moment';

import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import {
  getCodeGroups,
  getCodes,
  getCodeTypes,
  getEquipments,
  getEquipmentsGroups,
  getEquipmentTypes,
} from '~/store/manager/actions';
import styles from './styles';
import PF2MOutlinedInput from '../PF2MOutlinedInput';

function renderTimePicker(field, values, onChange, disabled = false) {
  return (
    <KeyboardTimePicker
      {...field}
      value={values[field.field.name]}
      autoOk
      views={['hours', 'minutes']}
      ampm={false}
      inputVariant="outlined"
      format="HH:mm"
      onChange={onChange}
      invalidDateMessage=""
      InputProps={{
        style: {
          height: 40,
          fontFamily: 'Roboto',
          fontWeight: 300,
          fontSize: 14,
          color: '#647886',
        },
      }}
      disabled={disabled}
    />
  );
}

function renderPF2MSearchSelectOutlined(
  field,
  form,
  classes,
  items,
  disabled = false,
  onChange = null,
  clearedValue = 0,
) {
  return (
    <PF2MSearchSelectOutlined
      {...field}
      disabled={disabled}
      placeholder={null}
      className={classnames(classes.fieldSelect)}
      onChange={onChange || field.onChange}
      clearedValue={clearedValue}
    >
      {items}
    </PF2MSearchSelectOutlined>
  );
}

const CODE_TYPE_MAINTENANCE = 0;
const CODE_GROUP_PREVENTIVE = 5;
const INFRAESTRUCTURE_EQUIPS = [5, 6, 7, 8, 9, 10, 12, 24, 25, 26, 27, 28];
const CODE_EFFECTIVE = 2;
const TRUCK_EQUIPMENT = 2;

const PF2MEquipmentScheduleModal = ({
  classes, setModalOpen, fixed = {}, settings, closeModal,
}) => {
  const { t: translate } = useTranslation();
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const equipments = useSelector(state => state.manager.equipments);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const preventiveTypes = useSelector(state => state.dispatch.preventiveTypes);
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);
  const isEquipmentsSchedulesDirty = useSelector(st => st.dispatch.isEquipmentsSchedulesDirty);
  const pendingStatus = useSelector(state => state.dispatch.equipmentScheduleStatuses.find(
    r => r.name === 'Pendente' || r.name === 'Pending' || r.name === 'Pendiente',
  ));

  const dispatch = useDispatch();

  const formikRef = useRef();

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!settings.skipBulkUpdate) {
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getCodeTypes());
      dispatch(getEquipmentsSchedules());
      dispatch(getEquipmentScheduleStatuses());
    }
    dispatch(getCodeGroups());
    dispatch(getCodes());
    dispatch(getPreventiveTypes());
  }, [dispatch, settings.skipBulkUpdate]);

  const calculateEndTime = (startDate, startTime, duration) => {
    if (startDate && startTime && duration && formikRef && formikRef.current) {
      startDate.set('hours', startTime.get('hours'));
      startDate.set('minutes', startTime.get('minutes'));
      const newDate = parseDateToServerFormat(startDate);
      newDate.set('seconds', 0);
      newDate.add({
        hour: Number(duration.split(':')[0]),
        minute: Number(duration.split(':')[1]),
      });

      formikRef.current.setFieldValue('endDate', newDate);
      formikRef.current.setFieldValue('endTime', newDate);
    }
  };

  // eslint-disable-next-line max-len
  const isTruckEquipment = equipTypeId => TRUCK_EQUIPMENT === equipTypeId;

  const getGroupFromEquipment = (equipId) => {
    const equipment = equipments.filter(r => r.id === equipId);
    if (!equipment.length) return 0;
    const group = equipmentGroups.filter(r => r.id === equipment[0].id_group);
    if (!group.length) return 0;
    return group[0].id;
  };

  const createSchedulesFromForm = (values) => {
    const code = codes.find(c => c.id === values.code);
    const baseSchedule = {
      start_date: formatDateToServerFormat(values.startDate),
      start_time: formatTime(values.startTime),
      duration: values.duration,
      end_date: formatDateToServerFormat(values.endDate),
      end_time: values.endTime ? formatTime(values.endTime) : null,
      delay: null,
      equipaments_id: values.equipmentType,
      equipament_groups_id: values.equipmentGroup,
      group_equipament_links_id: values.equipment,
      flowchart_element_id: values.element,
      flowchart_element_point_id: values.subElement,
      code_types_id: values.codeType,
      code_groups_id: values.codeGroup,
      codes_id: values.code,
      code_groups_id_pk: null,
      codes_id_pk: code.id_pk,
      preventive_type: values.preventiveType,
      observation: values.observation,
      flowchart_element_destination_id: values.destinationElement
        ? values.destinationElement : null,
      flowchart_element_point_destination_id: values.destinationSubElement
        ? values.destinationSubElement : null,
      material_id: values.material ? values.material : null,
      secondary_equipment_id: values.secondaryEquipment
        ? values.secondaryEquipment : null,
      status_id: pendingStatus.id,
    };
    const schedules = [];

    if (values.equipments.length) {
      values.equipments.forEach(r => schedules.push({
        ...baseSchedule,
        group_equipament_links_id: r,
      }));
    } else if (values.equipment !== 0) {
      schedules.push({
        ...baseSchedule,
        group_equipament_links_id: values.equipment,
      });

      if (values.truckEquipments.length) {
        values.truckEquipments.forEach(r => schedules.push({
          ...baseSchedule,
          equipaments_id: TRUCK_EQUIPMENT,
          equipament_groups_id: getGroupFromEquipment(r),
          group_equipament_links_id: r,
          secondary_equipment_id: values.equipment,
        }));
      }
    }
    return schedules;
  };

  const equipmentTypesMenuItems = useMemo(() => (
    [{ value: 0, label: translate('common:Select') }]
      .concat(...equipmentTypes
        .map(e => ({ value: e.id, label: e.name })))
  ), [equipmentTypes, translate]);

  const preventiveTypesMenuItems = useMemo(() => (
    [{ value: 0, label: translate('common:Select') }]
      .concat(...preventiveTypes
        .map(e => ({ value: e.id, label: e.name })))
  ), [preventiveTypes, translate]);

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {translate('common:NewSchedule')}
      </Typography>
    </div>
  );

  const renderDatePicker = (field, errors, disabled = false, onChange = null) => (
    <PF2MDatePickerOutlined
      {...field}
      disableFuture={false}
      className={errors[field.name] ? classes.fieldError : ''}
      disablePast
      helperText=""
      onChange={onChange || field.field.onChange}
      disabled={disabled}
    />
  );

  const renderCollections = ({
    items,
    filter = null,
    defaultKey = 'id',
    defaultValue = 0,
  }) => {
    const filteredItems = filter
      ? [...items.filter(filter)]
      : [...items];

    const menuItems = filteredItems
      .map(e => ({ value: e[defaultKey], label: e.name }));
    return (
      [{ value: defaultValue, label: translate('common:Select') }]
        .concat(menuItems));
  };

  useEffect(() => {
    if (isEquipmentsSchedulesDirty) {
      dispatch(updateEquipmentsSchedules(equipmentsSchedules, currentFilter));
      setModalOpen(false);
    }
  }, [isEquipmentsSchedulesDirty, currentFilter, dispatch, equipmentsSchedules, setModalOpen]);

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <Formik
            ref={formikRef}
            initialValues={{
              startDate: newMomentDate(),
              startTime: fixed.startTime ? fixed.startTime : moment('00:00:00', 'HH:mm:ss'),
              duration: fixed.duration ? fixed.duration : '00:00:00',
              endDate: newMomentDate(),
              endTime: null,
              equipmentType: fixed.equipmentType || 0,
              equipmentGroup: fixed.equipmentGroup || 0,
              equipment: fixed.equipment || 0,
              equipments: fixed.equipment ? [fixed.equipment] : [],
              preventiveType: fixed.preventiveType || 0,
              codeType: fixed.codeType || -1,
              codeGroup: fixed.codeGroup || 0,
              code: fixed.code || 0,
              element: fixed.element || 0,
              subElement: fixed.subElement || 0,
              observation: '',
              material: fixed.material || 0,
              destinationElement: fixed.destinationElement || 0,
              destinationSubElement: fixed.destinationSubElement || 0,
              secondaryEquipmentGroup: 0,
              secondaryEquipment: 0,
              truckEquipmentGroups: [],
              truckEquipments: [],
            }}
            validate={(values) => {
              const {
                startDate: formikStartDate,
                startTime: formikStartTime,
                duration: formikDuration,
                code,
                codeType,
                equipmentType,
                equipmentGroup,
                equipment,
                equipments: valueEquips,
                element,
                subElement,
                observation,
              } = values;

              const errors = {};
              if (!formikStartDate?.isValid || !formikStartDate.isValid()) errors.startDate = '*';
              if (!verifyTime(formikStartTime)) errors.startTime = '*';

              if (formikDuration === '00:00:00'
                || formikDuration === ''
                || !formikDuration.match(/\d{2}:\d{2}:\d{2}/)) errors.duration = '*';

              if (codeType === -1) errors.codeType = '*';
              if (code === 0) errors.code = '*';
              if (element === 0) errors.element = '*';
              if (subElement === 0) errors.subElement = '*';
              if (equipmentType === 0) errors.equipmentType = '*';
              if (equipmentGroup === 0) errors.equipmentGroup = '*';

              if (observation.length > 2048) errors.observation = translate('tips:LimitExceeded');
              if (valueEquips.length === 0 && equipment === 0) errors.equipment = '*';

              return errors;
            }}
            onSubmit={async (values, { resetForm }) => {
              const newSchedules = createSchedulesFromForm(values);
              dispatch(addEquipmentsSchedule(newSchedules));
              closeModal();
              resetForm();
            }}
          >
            {({
              isSubmitting,
              errors,
              values,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                {/* linha horarios formulario */}
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 200px)',
                  gridColumnGap: 10,
                  gridRowGap: 10,
                  margin: '10px 0',
                }}
                >
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:StartDate')}
                      <ErrorMessage
                        name="startDate"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="startDate"
                        render={field => renderDatePicker(field, errors, false, (e) => {
                          if (e?.isValid()) calculateEndTime(e, values.startTime, values.duration);
                          setFieldValue('startDate', e);
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:InitialTime')}
                      <ErrorMessage
                        name="startTime"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="startTime"
                        render={field => renderTimePicker(field, values, (e) => {
                          if (e?.isValid()) calculateEndTime(values.startDate, e, values.duration);
                          setFieldValue('startTime', e);
                        })}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:Duration')}
                      <ErrorMessage
                        name="duration"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="duration"
                        render={() => (
                          <PF2MMaskTextField
                            variant="outlined"
                            mask="99:99:99"
                            className={classes.textField}
                            placeholder={'00:00:00'}
                            onBlur={(e) => {
                              const { value } = e.target;
                              if (value) {
                                calculateEndTime(values.startDate, values.startTime, value);
                              }
                              setFieldValue('duration', value);
                            }}
                            InputProps={{
                              style: {
                                height: 40,
                                fontFamily: 'Roboto',
                                fontWeight: 300,
                                fontSize: 14,
                                color: '#647886',
                              },
                            }}
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:EndDate')}
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="endDate"
                        render={field => renderDatePicker(field, errors, true, null)}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:EndTime')}
                      <ErrorMessage
                        name="endTime"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="endTime"
                        render={field => renderTimePicker(field, values, (e) => {
                          setFieldValue('endTime', e);
                        }, true)}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:EquipmentType')}
                      <ErrorMessage
                        name="equipmentType"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="equipmentType"
                        render={({ field }) => (
                          <PF2MSearchSelectOutlined
                            {...field}
                            placeholder={null}
                            className={classnames(classes.fieldSelect)}
                            disabled={!!fixed.equipmentType}
                            onChange={(e) => {
                              setFieldValue('equipmentType', e.target.value);
                              setFieldValue('equipmentGroup', 0);
                              setFieldValue('equipment', 0);
                              setFieldValue('equipments', []);
                              setFieldValue('truckEquipmentGroups', []);
                              setFieldValue('truckEquipments', []);
                              setFieldValue('material', 0);
                              setFieldValue('destinationElement', 0);
                              setFieldValue('destinationSubElement', 0);
                              setFieldValue('secondaryEquipmentGroup', 0);
                              setFieldValue('secondaryEquipment', 0);
                            }}
                          >
                            {equipmentTypesMenuItems}
                          </PF2MSearchSelectOutlined>
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:EquipmentGroup')}
                      <ErrorMessage
                        name="equipmentGroup"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="equipmentGroup"
                        render={({ field, form }) => renderPF2MSearchSelectOutlined(
                          field,
                          form,
                          classes,
                          renderCollections({
                            items: equipmentGroups,
                            filter: e => values.equipmentType === e.id_equipament,
                          }),
                          !!fixed.equipmentGroup,
                          (e) => {
                            setFieldValue('equipmentGroup', e.target.value);
                            setFieldValue('equipment', 0);
                            setFieldValue('equipments', []);
                          },
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:Equipment')}
                      <ErrorMessage
                        name="equipment"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      {
                        isTruckEquipment(values.equipmentType)
                          ? (
                            <Field
                              name="equipments"
                              render={({ field }) => (
                                <PF2MSearchSelectOutlined
                                  value={field.value}
                                  className={classnames(classes.fieldSelect)}
                                  onChange={e => setFieldValue(
                                    'equipments', [...e.target.value.map(r => r.value)],
                                  )}
                                  disabled={!!fixed.equipment}
                                  multiple
                                  hideSelectedOptions={false}
                                  closeMenuOnSelect={false}
                                  placeholder={translate('common:Select')}
                                >
                                  {equipments
                                    .filter(r => isTruckEquipment(r.id_equip)
                                      && r.id_group === values.equipmentGroup)
                                    .map(r => ({ value: r.id, label: r.name }))}
                                </PF2MSearchSelectOutlined>
                              )}
                            />
                          ) : (
                            <Field
                              name="equipment"
                              render={({ field, form }) => renderPF2MSearchSelectOutlined(
                                field,
                                form,
                                classes,
                                renderCollections({
                                  items: equipments,
                                  filter: e => values.equipmentGroup === e.id_group,
                                }),
                                !!fixed.equipment,
                                e => form.setFieldValue('equipment', e.target.value),
                              )}
                            />
                          )
                      }
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:Element')}
                      <ErrorMessage
                        name="element"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="element"
                        render={({ field, form }) => renderPF2MSearchSelectOutlined(
                          field,
                          form,
                          classes,
                          renderCollections({ items: elements }), false, (e) => {
                            setFieldValue('element', e.target.value);
                            setFieldValue('subElement', 0);
                          },
                        )
                        }
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:SubElement')}
                      <ErrorMessage name="subElement" component="span" className={classes.errorMessage} />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="subElement"
                        render={({ field, form }) => renderPF2MSearchSelectOutlined(
                          field,
                          form,
                          classes,
                          renderCollections({
                            items: subElements,
                            filter: s => s.id_element === values.element,
                          }), false, e => form.setFieldValue('subElement', e.target.value),
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl style={{ gridColumnStart: 1 }}>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:CodeType')}
                      <ErrorMessage name="codeType" component="span" className={classes.errorMessage} />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="codeType"
                        render={({ field }) => (
                          <PF2MSearchSelectOutlined
                            {...field}
                            placeholder={null}
                            className={classnames(classes.fieldSelect)}
                            clearedValue={-1}
                            onChange={(e) => {
                              setFieldValue('codeType', e.target.value);
                              setFieldValue('codeGroup', 0);
                              setFieldValue('code', 0);
                              if (e.target.value !== CODE_TYPE_MAINTENANCE) {
                                setFieldValue('preventiveType', 0);
                              }
                            }}
                          >
                            {renderCollections({
                              items: codeTypes.filter(c => (
                                ![4, 15, 1, ...INFRAESTRUCTURE_EQUIPS]
                                  .some(a => a === values.equipmentType)
                                  ? c.id !== CODE_EFFECTIVE : true)),
                              defaultValue: -1,
                            })}
                          </PF2MSearchSelectOutlined>
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:CodeGroup')}
                      <ErrorMessage
                        name="codeGroup"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="codeGroup"
                        render={({ field, form }) => renderPF2MSearchSelectOutlined(
                          field,
                          form,
                          classes,
                          renderCollections({
                            items: codeGroups.filter(e => (values.codeType === e.code_type
                              && e.id_equip === values.equipmentType
                            )).filter(d => (
                              values.codeType === CODE_EFFECTIVE ? [13, 1].includes(d.id) : true
                            )),
                            defaultKey: 'id',
                          }), false, e => form.setFieldValue('codeGroup', e.target.value),
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:Code')}
                      <ErrorMessage name="code" component="span" className={classes.errorMessage} />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="code"
                        render={({ field, form }) => renderPF2MSearchSelectOutlined(
                          field,
                          form,
                          classes,
                          renderCollections({
                            items: codes,
                            filter: e => values.codeType === e.code_type
                              && values.codeGroup === e.id_group
                              && e.id_equip === values.equipmentType,
                            defaultKey: 'id',
                          }), false, e => form.setFieldValue('code', e.target.value),
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel className={classes.formLabel}>
                      {translate('common:PreventiveType')}
                      <ErrorMessage
                        name="preventiveType"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        name="preventiveType"
                        render={({ field, form }) => {
                          const { codeType, codeGroup } = form.values;
                          const disabled = (codeType !== CODE_TYPE_MAINTENANCE
                            || codeGroup !== CODE_GROUP_PREVENTIVE);
                          return renderPF2MSearchSelectOutlined(
                            field, form, classes, preventiveTypesMenuItems, disabled,
                            e => setFieldValue('preventiveType', e.target.value),
                          );
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                {/* fim linha combos formulario inclusao */}
                <div style={{ display: 'flex' }}>
                  <FormControl className={classes.classObservation}>
                    <FormLabel className={classes.formLabel}>
                      {`${translate('common:Observation')}:`}
                      <ErrorMessage
                        name="observation"
                        component="span"
                        className={classes.errorMessage}
                      />
                    </FormLabel>
                    <FormGroup>
                      <Field
                        type="textarea"
                        name="observation"
                        className={classnames(classes.field, errors.observation ? classes.fieldError : '')}
                        render={({ field }) => (
                          <PF2MOutlinedInput
                            {...field}
                            onChange={e => setFieldValue('observation', e.target.value)}
                            rows={20}
                            multiline
                          />
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                  <PF2MButton
                    variant="extended"
                    size="medium"
                    color="primary"
                    onClick={() => {
                      resetForm();
                      setModalOpen(false);
                    }}
                    className={classes.buttonWhiteText}
                  >
                    <DeleteOutline />
                    {translate('common:DiscardChanges')}
                  </PF2MButton>
                  <PF2MButton
                    type="submit"
                    className={classes.buttonWhiteText}
                    disabled={isSubmitting}
                  >
                    <Add className={classes.icon} />
                    {translate('common:Register')}
                  </PF2MButton>
                </div>
              </Form>
            )}
          </Formik>
        </CardBody>
        <PF2MAlertDialog
          hasCancel={false}
          confirmText={translate('common:Understood')}
          description={translate('validation:ExistingSchedule')}
          open={showAlert}
          onConfirm={() => setShowAlert(false)}
        />
      </Card>
    </div>
  );
};

PF2MEquipmentScheduleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setModalOpen: PropTypes.func,
  fixed: PropTypes.object,
  settings: PropTypes.object,
  closeModal: PropTypes.func,
};

PF2MEquipmentScheduleModal.defaultProps = {
  setModalOpen: () => null,
  closeModal: () => null,
  fixed: {},
  settings: {},
};

export default withStyles(styles)(PF2MEquipmentScheduleModal);
