import React, { useState, useEffect } from 'react';
import {
  Typography, Tab, Tabs, AppBar, Fab, FormControl, FormLabel, FormGroup,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import { DispatchOperationTypeName } from '~/utils';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import ChatTab from '~/pages/Dispatch/DispatchControl/ChatTab';
import {
  setSelectedLoadOrigin,
  setSelectedTruckOrigin,
  updateEquipmentStatus,
  setCurrentEquipmentStatus,
  getEquipmentStatuses,
} from '~/store/dispatch/actions';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import {
  filterCodeGroups,
  filterCodes,
  filterSubElements,
  filterOperators,
  useOnceEffect,
  getNameInListById,
} from './utils';
import {
  getCodeGroups,
  getCodes,
  getCodeTypes,
  getOperators,
  getOperatorsGroups,
} from '~/store/manager/actions';
import { getElements, getSubElements } from '~/store/nref/actions';
import { readChat } from '~/store/chat/actions';
import {
  formatDateTime, parseTimestamp,
} from '~/utils/moment';

// eslint-disable-next-line no-unused-vars
const ExcavatorModal = React.forwardRef(({ modalData, closeModal, classes }, _ref) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const excavator = useSelector((state) => {
    if (typeof modalData === 'number') {
      // pega modalData do redux
      const ex = state.dispatch.excavatorOrigins.find(t => t.equip_id === modalData);
      if (!ex) return { error: true };
      return ex;
    }
    return modalData;
  });
  const currentEquipmentStatus = useSelector(state => state.dispatch.currentEquipmentStatus);
  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const elements = useSelector(state => state.nref.elements);
  const subElements = useSelector(state => state.nref.subElements);
  const operators = useSelector(state => state.manager.operators);
  const operatorGroups = useSelector(state => state.manager.operatorsGroups);
  const chatReadStatus = useSelector(state => state.chat.chatList?.[modalData.equip_id]?.isReaded);
  const chatStatus = useSelector(
    state => state.manager.platformConfiguration.reduce((acc, e) => (e.key === 'enable_chat' ? e : acc), { value: '0' }),
  );
  const [selectedModalTab, setSelectedModalTab] = useState(0);

  useEffect(() => {
    if (excavator.equip_id) {
      dispatch(setCurrentEquipmentStatus(
        equipmentStatuses.find(e => e.id_equip === excavator.equip_id),
      ));
    }
  }, [equipmentStatuses, excavator.equip_id, dispatch]);

  useOnceEffect(() => {
    // when enter on modal
    dispatch(getEquipmentStatuses());
    dispatch(getCodeTypes());
    dispatch(getCodeGroups());
    return true;
  });

  useOnceEffect(() => {
    if (selectedModalTab === 1) {
      // only if on tab 1
      dispatch(getCodes());
      dispatch(getSubElements());
      dispatch(getElements());
      dispatch(getOperators());
      dispatch(getOperatorsGroups());
      return true;
    }
    return false;
  });

  const handleClose = () => {
    dispatch(setSelectedLoadOrigin(null));
    dispatch(setSelectedTruckOrigin(null));
    closeModal();
  };

  const renderDisplayField = (label = '', value = '-') => (
    <div>
      <div className={classes.labelTypeSelector}>
        {`${label}:`}
      </div>
      <div style={{ color: '#647886' }}>
        {value}
      </div>
    </div>
  );

  const renderOperationsTab = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
    >
      {renderDisplayField(
        translate('common:OperatorGroup'),
        getNameInListById(currentEquipmentStatus.id_operator_group, operatorGroups, 'id'),
      )}
      {renderDisplayField(translate('common:Operator'), currentEquipmentStatus.operator_name)}
      {renderDisplayField(translate('common:CurrentElement'), currentEquipmentStatus.element_name)}
      {renderDisplayField(
        translate('common:CurrentElementPoint'), currentEquipmentStatus.point_name,
      )}
      {renderDisplayField(
        translate('common:CurrentCycleStep'), DispatchOperationTypeName[excavator.operation_type],
      )}
      {renderDisplayField(translate('common:Material'), excavator.current_material_name)}
    </div>
  );

  const modalHandleChange = (field, value) => {
    dispatch(setCurrentEquipmentStatus(currentEquipmentStatus, field, value));
  };

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
    collectionId = 'id',
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames(classes.field)}
          value={item[key] || 0}
          onChange={e => modalHandleChange(key, e.target.value)}
        >
          {[{ value: initialValue, label: translate('common:Select') }]
            .concat(collection
              .map(e => ({ value: e[collectionId], label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  const renderStatusEditTab = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
    >
      {renderFormControl(currentEquipmentStatus, 'code_type', translate('common:CodeType'), codeTypes, -1)}
      {renderFormControl(currentEquipmentStatus, 'id_code_group', translate('common:CodeGroup'),
        codeGroups.filter(u => filterCodeGroups(u, currentEquipmentStatus)))}
      {renderFormControl(currentEquipmentStatus, 'id_code', translate('common:Code'),
        codes.filter(u => filterCodes(u, currentEquipmentStatus)))}
      {renderFormControl(currentEquipmentStatus, 'element_id', translate('common:Element'), elements)}
      {renderFormControl(currentEquipmentStatus, 'point_id', translate('common:SubElement'),
        subElements.filter(u => filterSubElements(u, currentEquipmentStatus)))}
      {renderFormControl(currentEquipmentStatus, 'id_operator_group', translate('common:OperatorGroup'), operatorGroups)}
      {renderFormControl(currentEquipmentStatus, 'id_operator', translate('common:Operator'),
        operators.filter(u => filterOperators(u, currentEquipmentStatus)), 0, 'id_operator')}
      <div>
        <FormControl style={{
          display: 'block',
        }}
        >
          <FormGroup>
            <FormLabel className={classes.formLabel}>
              {`${translate('common:Comment')}:`}
            </FormLabel>
            <PF2MOutlinedInput
              className={classes.textArea}
              value={currentEquipmentStatus.comment || ''}
              onBlur={e => modalHandleChange('comment', e.target.value)}
              multiline
              rows={2}
            />
          </FormGroup>
        </FormControl>
      </div>
      <Fab
        variant="extended"
        size="medium"
        color="secondary"
        onClick={() => {
          dispatch(updateEquipmentStatus(currentEquipmentStatus));
        }}
        className={classes.saveButton}
      >
        {translate('common:SaveData')}
      </Fab>
    </div>
  );

  const renderStatusTab = () => (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
    >
      {renderDisplayField(
        translate('common:CodeType'),
        getNameInListById(currentEquipmentStatus.code_type, codeTypes, 'id'),
      )}
      {renderDisplayField(
        translate('common:CodeGroup'), currentEquipmentStatus.code_group_name,
      )}
      {renderDisplayField(
        translate('common:Code'), currentEquipmentStatus.code_name,
      )}
      {renderDisplayField(
        translate('common:CurrentElement'), currentEquipmentStatus.element_name,
      )}
      {renderDisplayField(
        translate('common:CurrentElementPoint'), currentEquipmentStatus.point_name,
      )}
      {renderDisplayField(
        translate('common:OperatorGroup'),
        getNameInListById(currentEquipmentStatus.id_operator_group, operatorGroups, 'id'),
      )}
      {renderDisplayField(
        translate('common:Operator'), currentEquipmentStatus.operator_name,
      )}
    </div>
  );

  const renderHeader = () => (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
          {`${translate('common:Equipment')}: ${excavator.equip_name}`}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
        >
          {`${translate('common:Fleet')}: ${excavator.equip_group_name}`}
        </Typography>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        {renderDisplayField(translate('common:LastServerConnection'),
          excavator.timestamp_sync_request
            ? formatDateTime(parseTimestamp(excavator.timestamp_sync_request / 1)) : 'N/A')}
        {renderDisplayField(translate('common:LastDataSent'),
          excavator.timestamp_last_data_sent
            ? formatDateTime(parseTimestamp(excavator.timestamp_last_data_sent / 1)) : 'N/A')}
        {renderDisplayField(translate('common:DiconnectedTime'), excavator.tempo_diff ? `${excavator.tempo_diff } min` : 'N/A')}
        {renderDisplayField(translate('common:DataToSend'), excavator.remaining_data_in_package) || 'N/A'}

      </div>
    </div>
  );

  const renderTabBar = () => (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'white',
        boxShadow: 'none',
      }}
    >
      <div>
        <Tabs
          value={selectedModalTab}
          onChange={(event, value) => {
            setSelectedModalTab(value);
            dispatch(readChat(modalData.equip_id));
          }}
          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
        >
          <Tab
            label={'Status'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={'Edit Status'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          {chatStatus.value === '1' && (
            <Tab
              label={translate('common:ChatBeta')}
              classes={{
                root: chatReadStatus ? classes.tabRoot : classes.chatTabWithMessage,
                selected: classes.tabSelected,
              }}
            />
          )}
          <Tab
            label={'Operação'}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
        </Tabs>
      </div>
    </AppBar>
  );

  return (
    <div style={{ width: 800, height: 600 }}>
      <Card style={{ height: '100%' }}>
        <CardHeader icon>
          <>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
            <CardIcon
              onClick={handleClose}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '10px 10px 50px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
          </>
        </CardHeader>
        <CardBody>
          <div />
          {excavator.error ? (
            <div>
              <h2>{translate('common:FailToLoadData')}</h2>
            </div>
          ) : (
            <div>
              {renderHeader()}
              <hr />
              <div>
                {renderTabBar()}
                <div style={{ padding: 20 }}>
                  {selectedModalTab === 0 && renderStatusTab()}
                  {selectedModalTab === 1 && renderStatusEditTab()}
                  {chatStatus.value === '1' && selectedModalTab === 2 && <ChatTab selectedChatId={modalData.equip_id} />}
                  {(selectedModalTab === 3 || (chatStatus.value === '0' && selectedModalTab === 2)) && renderOperationsTab()}
                </div>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
});

ExcavatorModal.propTypes = {
  classes: PropTypes.shape({
    labelTypeSelector: PropTypes.string.isRequired,
    formLabel: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    textArea: PropTypes.string,
    saveButton: PropTypes.string.isRequired,
    tabsRoot: PropTypes.string,
    tabSelected: PropTypes.string.isRequired,
    tabsIndicator: PropTypes.string.isRequired,
    tabRoot: PropTypes.string.isRequired,
    chatTabWithMessage: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  // Object to facilitate if the modal needs more logic
  modalData: PropTypes.oneOfType([
    // it's possible to pass two values
    PropTypes.number, // excavatorId for fetch the info
    PropTypes.shape({ // the necessaryInfo for the modal
      equip_id: PropTypes.number.isRequired,
      operator_group_name: PropTypes.string.isRequired,
      operator_name: PropTypes.string.isRequired,
      current_element_name: PropTypes.string.isRequired,
      current_element_point_name: PropTypes.string.isRequired,
      operation_type: PropTypes.string.isRequired,
      current_material_name: PropTypes.string.isRequired,
      code_type_id: PropTypes.number.isRequired,
      code_group_name: PropTypes.string.isRequired,
      code_name: PropTypes.string.isRequired,
      equip_name: PropTypes.string.isRequired,
      equip_type_name: PropTypes.string.isRequired,
      equip_group_name: PropTypes.string.isRequired,
      last_modified: PropTypes.string.isRequired,
    }),
    PropTypes.any,
  ]),
};

ExcavatorModal.defaultProps = {
  modalData: null,
};

export default ExcavatorModal;
